import React, {useEffect, useState} from "react";
import styles from "./Match.module.scss";
import classNames from "classnames";
import twodots from "../../assets/images/2dots.svg";
import cupIcon from "../../assets/images/cupIcon.svg"
import {Link, useLocation, useParams} from "react-router-dom";
import {t} from "i18next";
import * as StatisticsService from "../../services/StatisticsService";
import * as MatchConfigService from "../../services/MatchConfigService";

const MatchScoreHeader = ({matchData, token}) => {
   let [loadingInProgress, setLoading] = useState(true);
   const location = useLocation();
   let {matchId} = useParams();
   const [tournamentData, setTournamentData] = useState(matchData.tournament);
   const [teamOneOdd, setTeamOneOdd] = useState(0.0);
   const [teamTwoOdd, setTeamTwoOdd] = useState(0.0);
   const [OddResponse, setOddResponse] = useState({});

   useEffect(() => {
      const getMatchStats = async () => {
         await StatisticsService.getMatchStat(matchId, token).then((response) => {
            if (response?.data?.apiStatus) {
               setTournamentData(response?.data?.match?.tournament)
            }
         });
      };

      getMatchStats();
   }, [matchId, token]);

   useEffect(() => {
      const getLatestOddsByMatchId = async () => {
         try {
            await MatchConfigService.getLatestOddsByMatch(matchId).then((response) => {
               if (response?.apiStatus) {
                  setOddResponse(response);
                  if (matchData.teamOne.teamId === response.teamOneId) {
                     setTeamOneOdd((response?.teamOneOdd).toFixed(2));
                     setTeamTwoOdd((response?.teamTwoOdd).toFixed(2));
                  } else {
                     setTeamTwoOdd((response?.teamOneOdd).toFixed(2));
                     setTeamOneOdd((response?.teamTwoOdd).toFixed(2));
                  }
               } else {

               }
            })
         } catch (e) {
            console.log(e);
         }
      }

      getLatestOddsByMatchId();
   }, [matchData?.matchInningList]);

   useEffect(() => {
      if (Object.keys(matchData).length != 0) {
         setLoading(false);
      }
   }, [matchData]);

   const formatedTournamentType = tournamentData?.type?.replace(/_/g, "-")?.toLowerCase();

   const scoreHeaderNameHandler = () => {
      if (!matchData?.live && !matchData?.toStart) {
         return matchData?.message;
      } else {
         return '';
      }
   }

   return (
      <>
         {matchData?.matchInningList && matchData?.matchInningList?.length > 0 ?
            <div className="row gx-0">
               {matchData?.live == true && matchData?.matchInningList ? (
                  <div className={classNames(styles.matchScoreHeaderWrapper, "col-12 gx-0 p-0")}>
                     <div className={classNames(styles.LiveMatchScore, "LiveMatchScore scoreBoard liveScore")}>
                        <Link to={`/${formatedTournamentType}/${tournamentData?.tournamentId}`} style={{textDecoration: "none", color: "white"}}>
                           <div className={classNames(styles.MatchTournamentHeader)}>{tournamentData?.name || ''}</div>
                        </Link>
                        {/*<WinningPercentage />*/}

                        <div className={classNames(styles.ScoreHeaderContainer)}>
                           <div className={classNames(styles.teamDetail, "teamDetail just-right ps-2 pe-2 p-0 p-sm-2 p-md-3")}>
                              <div className={classNames(styles.team1scoreSection, "team1scoreSection mt-0 mt-md-0")}>
                                 <Link to={{pathname: `/team/${matchData?.matchInningList[0]?.battingTeam?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                    <div className={classNames(styles.team1Name, "team1Name")}>
                                       {matchData?.matchInningList[0]?.battingTeam?.teamId ?
                                          <>
                                             {matchData?.matchInningList[0]?.battingTeam?.name}
                                             {matchData?.matchInningList[1] == undefined ? (
                                                <span className={styles.teamBat}></span>) : (
                                                <span className={styles.teamBall}></span>
                                             )}
                                          </> : <>
                                             {matchData?.teamOne?.name}
                                             <span className={styles.teamBat}></span>
                                          </>}
                                    </div>
                                 </Link>

                                 <div className={classNames(styles.team1Score, "team1Score")} style={{
                                    color: matchData?.winningTeamId == matchData?.matchInningList[0]?.battingTeam?.teamId && matchData?.live == false
                                       ? "#17EA85"
                                       : "#95A6B7",
                                    WebkitTextFillColor: matchData?.winningTeamId == matchData?.matchInningList[0]?.battingTeam?.teamId && matchData?.live == false
                                       ? "#17EA85"
                                       : "#95A6B7",
                                 }}>
                                    {(matchData?.matchInningList[0]?.runs != null) ?
                                       <>
                                          {matchData?.matchInningList[0]?.runs}/{matchData?.matchInningList[0]?.wickets}
                                       </> : <>
                                          0/0
                                       </>}
                                 </div>
                                 <div className={classNames(styles.team1Overs, "team1Overs")}>
                                    {matchData?.matchInningList[0]?.bowlerPositionOfOver != null && matchData?.matchInningList[0]?.numberOfOvers !== null ?
                                       <>
                                          ({(matchData?.matchInningList[0]?.bowlerPositionOfOver !== 6 ? matchData?.matchInningList[0]?.numberOfOvers - 1 : matchData?.matchInningList[0]?.numberOfOvers) == -1 ? 0 : (matchData?.matchInningList[0]?.bowlerPositionOfOver !== 6 ? matchData?.matchInningList[0]?.numberOfOvers - 1 : matchData?.matchInningList[0]?.numberOfOvers)}
                                          .
                                          {matchData?.matchInningList[0]
                                             ?.bowlerPositionOfOver !== 6
                                             ? matchData?.matchInningList[0]
                                                ?.bowlerPositionOfOver
                                             : 0}
                                          )
                                       </> : <>(0.0)</>}
                                 </div>

                                 {/*TODO : uncomment */}
                                 {/*<div className={classNames(styles.team1Odd, "team1Odd")} style={{align: 'right'}}>*/}
                                 {/*   {matchData?.teamOne?.teamId &&*/}
                                 {/*      <>Win : {matchData.teamOne.teamId === OddResponse.teamOneId ? teamOneOdd : teamTwoOdd || 0.0}</>}*/}
                                 {/*</div>*/}

                              </div>
                              <div className={classNames(styles.team1Logo, "team1Logo")}>
                                 {matchData?.matchInningList[0]?.battingTeam?.teamId ?
                                    <Link to={{pathname: `/team/${matchData?.matchInningList[0]?.battingTeam?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                       <img src={matchData?.matchInningList[0]?.battingTeam?.teamPictureURL} style={{borderRadius: '50%'}}/>
                                    </Link>
                                    :
                                    <Link to={{pathname: `/team/${matchData?.teamOne?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                       <img src={matchData?.teamOne?.teamPictureURL} style={{borderRadius: '50%'}}/>
                                    </Link>
                                 }
                              </div>
                           </div>
                           <div className={classNames(styles.midSect, "midSect")}>
                              {/* <label className="vsLbl">VS</label> */}
                              {/*<div className="midDiv">*/}
                              {/*  {matchData?.live == true &&*/}
                              {/*  matchData?.watchCount ? (*/}
                              {/*    <div>*/}
                              {/*      <span className="statLbl">{matchData?.watchCount}'</span>*/}
                              {/*    </div>*/}
                              {/*  ) : (*/}
                              {/*    <div>*/}
                              {/*      <span className="statLbl">0'</span>*/}
                              {/*    </div>*/}
                              {/*  )}*/}
                              {/*</div>*/}
                              <div className={classNames(styles.twodots, "twodots")}>
                                 <img src={twodots} style={{marginBottom: "5px"}}/>
                              </div>
                           </div>

                           <div className={classNames(styles.teamDetail, "teamDetail ps-2 pe-2 p-0 p-sm-2 p-md-3")}>
                              <div className={classNames(styles.team2Logo, "team2Logo")}>
                                 {matchData?.matchInningList[0]?.battingTeam?.teamId ?

                                    <Link to={{pathname: `/team/${matchData?.matchInningList[0]?.bowlingTeam?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                       <img src={matchData?.matchInningList[0]?.bowlingTeam?.teamPictureURL} style={{borderRadius: '50%'}}/>
                                    </Link>
                                    :
                                    <Link to={{pathname: `/team/${matchData?.teamTwo?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                       <img src={matchData?.teamTwo?.teamPictureURL} style={{borderRadius: '50%'}}/>
                                    </Link>
                                 }
                              </div>
                              <div className={classNames(styles.team2scoreSection, "team2scoreSection mt-0 mt-md-0")}>
                                 {matchData?.matchInningList[0]?.battingTeam?.teamId ?

                                    <Link to={{pathname: `/team/${matchData?.matchInningList[0]?.bowlingTeam?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                       <div className={classNames(styles.team2Name, "team2Name mt-3 mt-md-0")}>
                                          {matchData?.matchInningList[1] != undefined ?
                                             <span className={styles.teamBat}></span> :
                                             <span className={styles.teamBall}></span>
                                          }
                                          {matchData?.matchInningList[0]?.bowlingTeam?.name}
                                       </div>
                                    </Link>
                                    :
                                    <Link to={{pathname: `/team/${matchData?.teamTwo?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                       <div className={classNames(styles.team2Name, "team2Name mt-3 mt-md-0")}>

                                          <span className={styles.teamBall}></span>

                                          {matchData?.teamTwo?.name}
                                       </div>
                                    </Link>
                                 }
                                 {matchData?.matchInningList[1] === undefined ?
                                    <>
                                       <div className={classNames(styles.team2Score, "team2Score mt-0 mt-md-0")}>0/0</div>
                                       <div className={classNames(styles.team2Overs, "team2Overs")}>(0.0)</div>

                                       {/*TODO*/}
                                       {/*<div className={classNames(styles.team2Odd, "team2Odd")}>Win: {matchData.teamTwo.teamId === OddResponse.teamTwoId ? teamTwoOdd : teamOneOdd || 0.0}</div>*/}
                                    </>
                                    :
                                    <>
                                       <div className={classNames(styles.team2Score, "team2Score")}
                                            style={{
                                               color: matchData?.winningTeamId == matchData?.matchInningList[1]?.battingTeam?.teamId ?
                                                  "rgb(23, 234, 133)"
                                                  : "rgb(149, 166, 183)",
                                               WebkitTextFillColor: matchData?.winningTeamId == matchData?.matchInningList[1]?.battingTeam?.teamId ?
                                                  "rgb(23, 234, 133)"
                                                  : "rgb(149, 166, 183)"
                                            }}>
                                          {matchData?.matchInningList[1]?.runs}/{matchData?.matchInningList[1]?.wickets}
                                       </div>
                                       <div className={classNames(styles.team2Overs, "team2Overs")}>
                                          (
                                          {matchData?.matchInningList[1]
                                             ?.bowlerPositionOfOver !== 6
                                             ? matchData?.matchInningList[1]
                                             ?.numberOfOvers - 1
                                             : matchData?.matchInningList[1]
                                                ?.numberOfOvers}
                                          .
                                          {matchData?.matchInningList[1]
                                             ?.bowlerPositionOfOver !== 6
                                             ? matchData?.matchInningList[1]
                                                ?.bowlerPositionOfOver
                                             : 0}
                                          )
                                       </div>
                                       {/*TODO*/}
                                       {/*<div className={classNames(styles.team2Odd, "team2Odd")}>Win : {matchData.teamTwo.teamId === OddResponse.teamTwoId ? teamTwoOdd : teamOneOdd || 0.0}</div>*/}
                                    </>
                                 }
                              </div>
                           </div>
                        </div>
                        {/* {
                    nextMatchId != 0 ?
                  <div><a className="backBtnScore mobile-show" onClick={handleNextMatch}  style={{top: 13, color:"#fff"}}><BsChevronRight/></a></div>: <></>
                  } */}
                     </div>
                  </div>
               ) : (
                  <div className={classNames(styles.matchSummaryWrapper, styles.CompletedMatchSummaryWrapper, "col-12")}>
                     <div className={classNames(styles.scoreBoardDark, "liveScore")}>
                        <Link to={`/${formatedTournamentType}/${tournamentData?.tournamentId}`} style={{textDecoration: "none", color: "white"}}>
                           <div className={classNames(styles.MatchTournamentHeader)}>{scoreHeaderNameHandler()}</div>
                        </Link>
                        <div>
                           <div className={classNames(styles.teamDetail, "teamDetail just-right ms-3 ms-sm-0")}>
                              <div className={classNames(styles.team1scoreSectionWrapper, "team1scoreSection")}>
                                 <Link to={{pathname: `/team/${matchData?.matchInningList[0]?.battingTeam?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                    <div className={classNames(styles.teamNameWrapper, "team1Name")}>
                                       {matchData?.matchInningList[0]?.battingTeam?.name}
                                       {matchData?.winningTeamId == matchData?.matchInningList[0]?.battingTeam?.teamId && matchData?.live == false ?
                                          <img style={{paddingLeft: "2px", marginLeft: "6px"}} src={cupIcon}/> : ""}
                                    </div>
                                 </Link>
                                 <div className={classNames(styles.teamScoreWrapper, styles.team1Score, "team1Score")}
                                      style={{
                                         color: (matchData?.winningTeamId == matchData?.matchInningList[0]?.battingTeam?.teamId &&
                                            matchData?.live == false) ||
                                         (matchData?.live == true && matchData?.matchInningList[1] == undefined)
                                            ? "#17EA85"
                                            : "#95A6B7",
                                         WebkitTextFillColor: (matchData?.winningTeamId == matchData?.matchInningList[0]?.battingTeam?.teamId &&
                                            matchData?.live == false) ||
                                         (matchData?.live == true && matchData?.matchInningList[1] == undefined)
                                            ? "#17EA85"
                                            : "#95A6B7"
                                      }}
                                 >
                                    {matchData?.matchInningList[0]?.runs}/
                                    {matchData?.matchInningList[0]?.wickets}
                                 </div>
                                 <div className="team1Overs">
                                    (
                                    {(matchData?.matchInningList[0]?.bowlerPositionOfOver !== 6) ?
                                       ((matchData?.matchInningList[0]?.numberOfOvers > 0) ? (matchData?.matchInningList[0]?.numberOfOvers - 1) : 0)
                                       : (matchData?.matchInningList[0]?.numberOfOvers)}
                                    .
                                    {(matchData?.matchInningList[0]?.bowlerPositionOfOver !== 6) ? (matchData?.matchInningList[0]?.bowlerPositionOfOver) : 0}
                                    )
                                 </div>

                              </div>
                              <div className={classNames(styles.team1Logo, "team1Logo")}>
                                 <img
                                    src={
                                       matchData?.matchInningList[0]?.battingTeam
                                          ?.teamPictureURL
                                    }
                                 />
                              </div>
                           </div>

                           {matchData?.live === true ? (
                              <div className="midDiv">
                                        <span className="statLbl">
                                    {matchData?.watchCount}
                                  </span>
                              </div>
                           ) : (
                              ""
                           )}

                           <div className={classNames(styles.twodotsWrapper, styles.twodots, "twodots")}>
                              <img src={twodots}/>
                           </div>
                           <div className={classNames(styles.teamDetail, "teamDetail")}>
                              <div className="team2Logo">

                                 <img src={matchData?.matchInningList[0]?.bowlingTeam?.teamPictureURL}/>
                              </div>
                              <div className="team2scoreSection">
                                 <Link to={{pathname: `/team/${matchData?.matchInningList[0]?.bowlingTeam?.teamId}`, search: `returnUrl=${encodeURIComponent(location.pathname)}`}}>
                                    <div className={classNames(styles.teamNameWrapper, styles.team2Name, "team2Name")}>
                                       {
                                          matchData?.matchInningList[0]?.bowlingTeam
                                             ?.name
                                       }
                                       {

                                          matchData?.winningTeamId ==
                                          matchData?.matchInningList[0]
                                             ?.bowlingTeam?.teamId &&
                                          matchData?.live == false
                                             ? <img style={{paddingLeft: "2px", marginLeft: "6px"}} src={cupIcon}/>
                                             : ""
                                       }
                                    </div>
                                 </Link>
                                 {matchData?.matchInningList[1] ===
                                 undefined ? (
                                    <>
                                       <div className="team2Score">
                                          YET TO BAT
                                       </div>
                                    </>
                                 ) : (
                                    <>
                                       <div className={classNames(styles.teamScoreWrapper, "team2Score")}
                                            style={{
                                               color:
                                                  (matchData?.winningTeamId ==
                                                     matchData?.matchInningList[0]
                                                        ?.bowlingTeam?.teamId &&
                                                     matchData?.live == false) ||
                                                  (matchData?.live == true &&
                                                     matchData?.matchInningList[1] !=
                                                     undefined)
                                                     ? "#17EA85"
                                                     : "#95A6B7",
                                               WebkitTextFillColor:
                                                  (matchData?.winningTeamId ==
                                                     matchData?.matchInningList[0]
                                                        ?.bowlingTeam?.teamId &&
                                                     matchData?.live == false) ||
                                                  (matchData?.live == true &&
                                                     matchData?.matchInningList[1] !=
                                                     undefined)
                                                     ? "#17EA85"
                                                     : "#95A6B7"
                                            }}
                                       >
                                          {matchData?.matchInningList[1]?.runs}/
                                          {matchData?.matchInningList[1]?.wickets}
                                       </div>
                                       <div className={classNames(styles.team2Overs, "team2Overs")}>
                                          (
                                          {(matchData?.matchInningList[1]?.bowlerPositionOfOver !== 6) ?
                                             ((matchData?.matchInningList[1]?.numberOfOvers > 0) ? (matchData?.matchInningList[1]?.numberOfOvers - 1) : 0)
                                             : (matchData?.matchInningList[1]?.numberOfOvers)}
                                          .
                                          {(matchData?.matchInningList[1]?.bowlerPositionOfOver !== 6) ? (matchData?.matchInningList[1]?.bowlerPositionOfOver) : 0}
                                          )
                                       </div>
                                    </>
                                 )}
                              </div>
                           </div>
                        </div>
                        {/* {
                    nextMatchId != 0 ?
                  <a  className={classNames(styles.nextBtnScore, "backBtnScore mobile-show")} onClick={handleNextMatch} style={{top: 13, color:"#fff"}}><BsChevronRight/></a>: <></>
                  } */}
                     </div>
                  </div>
               )}
            </div>
            :
            <div className="gx-0 col-12 p-0 ">
               {matchData?.live == true ? (
                  <div>
                     <div className={classNames(styles.scoreBoard)} style={{height: 134, alignItems: "center", justifyContent: "center"}}>
                        <div className={classNames(styles.Text24Solina, "pt-3 ps-3 pe-3 pt-4 pt-md-5")}
                             style={{textAlign: "center", fontSize: "30px", lineHeight: "40px"}}>Match is starting soon..
                        </div>
                     </div>
                  </div>
               ) : (
                  <div>

                     {Object.keys(matchData).length != 0 && loadingInProgress ? (
                        <div className={styles.loaderContainer}>
                           <div className="lds-facebook">
                              <div></div>
                              <div></div>
                              <div></div>
                           </div>
                        </div>
                     ) : (
                        <>
                           {Object.keys(matchData).length == 0 && !loadingInProgress ?
                              <div className={classNames(styles.scoreBoardDark)}>
                                 <text>{t("MATCH.MATCH_DATA_IS_NOT_AVAILABLE")}</text>
                              </div> : ""}
                        </>

                     )}
                  </div>
               )}
            </div>
         }

      </>
   )

}

export default MatchScoreHeader;
